$("#search_home").on('input', function() {
    var value = $("#search_home").val();

    if(value.length >= 3){
        $('body').addClass('search_in');
    } else {
        $('body').removeClass('search_in');
    }
});

// /**
//  * Pour afficher le panier sur le coté (utilisé aussi dans product.js à l'ajout du panier)
//  */
function topCartAppear() {
    var show_top_cart_parent = $('#cart_top');
    var count_bask = $('#cart_top .item_link').attr('data-cart');

    if(count_bask > 2){
        $("#cart_list").lionbars();
    }

    show_top_cart_parent.addClass("hover");
    $('#shad_menu').addClass("actif");
}


$(function() {
    'use strict';
    $("#cart_top").on('mouseenter touchstart', function (evt) {
        var count_bask = $('#cart_top .cart').attr('data-cart');

        if(count_bask > 2){
            $("#cart_list").lionbars();
        }
    });
    
    $('.satellite_politique-confidentialite .content_sat .cookieShowLightbox').click(function() {
        $('.satellite_politique-confidentialite #cookiesLightbox').addClass('displayCookies');
        if($('.satellite_politique-confidentialite #cookiesLightbox').has('.hideCookies')) {
            $('.satellite_politique-confidentialite #cookiesLightbox').removeClass('hideCookies');
        }
        $('.satellite_politique-confidentialite #cookiesLightbox .w-form-line.w-submit button.w-loader').addClass('hideLoader');
    });

    $('.satellite_politique-confidentialite #js_cookie_refuse, .satellite_politique-confidentialite #js_cookie_accept').click(function() {
        if($('.satellite_politique-confidentialite #cookiesLightbox').has('.displayCookies')) {
            $('.satellite_politique-confidentialite #cookiesLightbox').removeClass('displayCookies');
            $('.satellite_politique-confidentialite #cookiesLightbox').addClass('hideCookies');
            $('.satellite_politique-confidentialite #shadCookie').removeClass('actif');
        }
    });
});


// /**
//  * Pour afficher le panier sur le coté (utilisé aussi dans product.js à l'ajout du panier)
//  */
function topCartDesappear() {
    var show_top_cart_parent = $('#cart_top');
    show_top_cart_parent.removeClass("hover");
    $('#shad_menu').removeClass("actif");
}

function closeRecherche(){
    if($('.wrap_search_field').is(":visible")) {
        $('.wrap_search_field').fadeOut();
        $('#shad_menu').removeClass('actif');
    }

    $('.searchItem ').mouseover(function () {
        $('.wrap_search_field').fadeIn();
    })

    $('.searchItem ').mouseleave(function () {
        $('.wrap_search_field').fadeIn();
    })
}

$('#search_home').keypress(function (e) {
    var key = e.which;

    if(key == 13 && $('#search_home').val().length >= 3){
        closeRecherche();
    }
});

function changeLocale(locale, uri, site) {
    $.ajax({
        url: path_relative_root + create_link('ajax_get_uri_by_lang'),
        type: 'post',
        data: {
            lang: locale,
            url: uri,
            site: site
        },
        success: function (data) {
            if (data != '') {
                var force = '';
                if (uri == '/rodier.fr') {
                    force = '?force';
                }
                location.href = data + force;
            }
        }
    });
}

function verif_mail(elementID, fieldname, is_spe) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + "Label");
    var erreurText = document.getElementById("erreur_msg");
    var mail = new RegExp(
        "^[a-z0-9_-]+([.]?[a-z0-9_-]{1,})*@([a-z0-9_-]{2,}[.])+[a-z]{2,4}$",
        "gi"
    );

    path = "";
    if (typeof path_relative_root != "undefined") path += path_relative_root;

    if (mail.exec(objElement.value) == null) {
        if (typeof is_spe == "undefined") {
            var translations = ajax_file(
                path +
                    "ajax_sprintf.php?arg0=%s|%s" +
                    "&arg1=" +
                    fieldname +
                    "&arg2=js_wrong_input_mail"
            );
            translations = translations.split("|");
            type_champ = translations[0];
            type_message = translations[1];

            erreurText.innerHTML =
                '<p class="title_toolltip">' +
                type_champ +
                " :</p>" +
                type_message;
        }
        $(objElement).addClass("inputErr");
        $(objLabel).addClass("labelErr");
        return false;
    }
    $(objElement).removeClass("inputErr");
    $(objLabel).removeClass("labelErr");
    return true;
}

function closeInfobulle(id) {
    if (!jQuery.browser.msie) $("#" + id).fadeOut(400);
    else document.getElementById(id).style.display = "none";
    document.getElementById(id).style.zIndex = "0";
}

/* pour les questions du panier panier */
function fadePanier(id, nbelem) {

    for (var i = 1; i <= nbelem; i++) {

        if (id != i && $("#texte_question_" + i).css('display') == 'block') {

            $("#texte_question_" + i).slideUp(400);
            $("#intitule_question_" + i).removeClass('actif');
        }
    }

    if ($("#texte_question_" + id).css('display') != 'block') {

        $("#texte_question_" + id).slideDown(400);
        $("#texte_question_" + id).addClass('actif');
        $("#intitule_question_" + id).addClass('actif');
    } else {

        $("#texte_question_" + id).slideUp(400);
        $("#texte_question_" + id).removeClass('actif');
        $("#intitule_question_" + id).removeClass('actif');
    }
}

/*************************************/
/******** affichelostpassword ********/
var afficheLostPassword = function (evt) {

    var signIn = $('#auth');
    var passWd = $('#resetPass');

    evt.preventDefault();

    signIn.animate({
        opacity: 0
    }, 600, function () {

        signIn.css('display', 'none');

        passWd.css('display', '').animate({
            opacity: 1
        }, 600);
    });
};

function close_popup(elem) {
    var $modbox = $("#" + elem);
    var $shad = $("#shad");
    $shad.attr("onclick", "");
    $shad.animate({opacity: 0}, 350, function() {
        $shad.css({display: "none", opacity: ""}).data("opened_lb", 0);
    });
    $modbox.css({display: "none"});
    //bodyScrollUnlock();
}

function openMultiShad(id) {

    // Show modbox and make sure closing routine won't fire more than once
    $("#" + id).fadeIn(200, function () {
        $("#" + id).slideDown(600);
    });

    if (id !== 'zoomBox') {
        $("#shad").css("display", "table").fadeTo("slow", 0.8).on("click", function () {
            closeMultiShad(id);
        });

        // bodyScrollLock();
    }

    if(id === "zoomBox") {

        $('#zoom_box_wrapper .swiper-slide').mousemove(function (event) {

            var slideOffset = $(this).offset();
            var relY = -1 * (event.pageY - slideOffset.top);

            $(this).find('img').css('top', relY + "px");
        });

        $('#zoom_box_wrapper .img_large').on('click', function () {

            closeMultiShad('zoomBox');
        });
    }

    if ($("#buyNowSwiper").length > 0) {

        window.setTimeout(function () {

            buyNowSwiper = new Swiper("#buyNowSwiper", {

                width: 260,
                height: 390,
                sliderPerView: 1,
                navigation: {
                    nextEl: '.modboxNext',
                    prevEl: '.modboxPrev',
                },
            });
        }, 50);
    }

    if (id === "sizeguideBox") {
        window.setTimeout(function () {
            $("#wrap_itable").lionbars();
        }, 200);
    }

    if(id == "selection-offer") {

        setTimeout(function (){

            $('.selection-offer-content').lionbars();

            var color_list = new Swiper('.color_list', {

                slidesPerView: 4,
                navigation: {
                    nextEl: '.nextColour',
                    prevEl: '.prevColour',
                }
            });

            var size_list = new Swiper('.size_list', {

                slidesPerView: 4,
                navigation: {
                    nextEl: '.nextSize',
                    prevEl: '.prevSize',
                }
            });
        }, 400);
    }
}


function closeMultiShad(id) {
    $("#shad").off("click");

    // Hide modbox and shade and destroy shade's click event
    $("#" + id)
        .slideUp("slow")
        .queue(function() {
            if ($("#shad").is(":visible")) {
                $("#shad").fadeTo("slow", 0, function() {
                    $("#shad").css("display", "none");
                });
            }

            $(this).dequeue();
    });
}

$.fn.selectChangeAdr = function() {
    var $obj = $(this);
    var obj_val = $obj.val();
    var id_transp = $obj.attr("id");
    var data = {};
    var $changeContainer = $(
        ".wrapper_form_adresse",
        $obj.closest(".adresse_change")
    );
    data.act = "ajout";
    data.id_transp = id_transp;
    data.no_encode_ajax = true;
    data.modif_type = "ajax";

    if (obj_val != "") {
        if (obj_val != 0) {
            data.idAdresse = obj_val;
            data.act = "modif";
        }
        if ($changeContainer.is(".show")) {
            $changeContainer
                .removeClass("show")
                .slideUp()
                .html("");
        }
        $.ajax({
            type: "get",
            data: data,
            url: path_relative_root + "livraison-ajax.php",
            success: function(response) {
                var $responce = $(response);
                var min_form = $(".min_form", $responce).html();
                var $form = $("#adresseForm", $responce);
                var all_form;

                $changeContainer
                    .html($form.html(min_form))
                    .css({display: "none"})
                    .slideDown();
                $(".styled").new_styling_form({
                    type_class_sufix: "",
                    localName_new_elem: "span",
                });
                if (!$changeContainer.is(".show")) {
                    $changeContainer.addClass("show").slideDown();
                }
                try {
                    new DeliveryPage().init();
                } catch (e) {
                }
                initialize_google_place("delivery");
                $(".type_adresse", ".choix_type_adresse.delivery").on(
                    "click",
                    function() {
                        $(this).show_adresse_title(
                            $(".type_adresse", ".choix_type_adresse.delivery"),
                            "#adresseTitre"
                        );
                    }
                );

                var country_select = "";

                if (typeof $("#pays").val() != "undefined") {
                    country_select = $("#pays")
                        .find(":selected")
                        .data("role")
                        .toLowerCase();

                    $("#pays").change(function() {
                        if (
                            typeof $(this)
                                .find(":selected")
                                .data("role") != "undefined"
                        ) {
                            country_select = $(this)
                                .find(":selected")
                                .data("role")
                                .toLowerCase();
                        }

                        if (typeof $("#telephone").val() != "undefined") {
                            $("#telephone").intlTelInput(
                                "setCountry",
                                country_select
                            );
                        }

                        if (typeof $("#mobile").val() != "undefined") {
                            $("#mobile").intlTelInput(
                                "setCountry",
                                country_select
                            );
                        }
                    });
                } else {
                    country_select = "fr";
                }
            },
        });
    }
};

// /**
//  * Peremt de récupérer les stocks des tailles par couleur ainsi que leur stock
//  * @author Timothy Beard / Aurélien Renault
//  * @since  2017-09-27
//  */
function setAvailableSizes() {
    if (window.json_colisages !== undefined) {
        var sizes_tab = window.json_colisages[$(this).val()];
        var size_box, is_disabled;

        for (size_id in sizes_tab) {
            if (sizes_tab.hasOwnProperty(size_id)) {
                size_box = $('.product_main_wrapper [name="itm_size"]').filter(
                    '[value="' + size_id + '"]'
                );
                is_disabled = !sizes_tab[size_id];
                if( is_disabled )  {
                    size_box.addClass('disabled');
                }
                else {
                    size_box.removeClass('disabled');
                }
            }
        }
    }
}

// // Show hide div Livraison Gratuit
function cookieHeaderDelivery() {
    var d = new Date();
    d.setTime(d.getTime() + (36500  * 24 * 60 * 60 * 1000));
    var expires = "expires="+d.toUTCString();

    document.cookie = "cookie_header_delivery=accepted;" + expires;
}

function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
    }

$(function() {
    var headerDelivery = $(".header_mention");

    headerDelivery.remove();

    if (getCookie("cookie_header_delivery") != "") {
        $("#site_head_wrap").removeClass("bandeau_on");
        return;
    } else {
        $("#site_head_wrap").prepend(headerDelivery);
        headerDelivery.slideDown(500);
        headerDelivery.addClass("display");
        $("body").addClass("bandeauDelivery");

        $(".close_delais").on("click", function() {
            cookieHeaderDelivery();
            headerDelivery.slideUp(500, function() {
                headerDelivery.remove();
            });
            $("body").removeClass("bandeauDelivery");
            $("#site_head_wrap").removeClass("bandeau_on");
        });
    }
});

$(".bloc_lightbox_preco").on("click", function() {
    if (!$(this).hasClass("actif")) {
        $(".bloc_lightbox_preco.actif").removeClass("actif");
        $(this).addClass("actif");
    }
});

function goBack(toUrl) {
    if (toUrl) {
        window.location.href = path_relative_root;
    } else {
        var url = window.location.href;
        if (url.indexOf("#search") > -1) {
            var new_url = url.substring(0, url.indexOf("#search"));
            window.location.href = new_url;
        } else {
            window.history.back();
        }
    }
}

$("#cart_top.hasSubMenu").hover(function() {
    $('nav.nav_header').toggleClass("hover_panier");
});

function redirectToProduct(url) {
    if(url) {
        window.location.href = url;
    }
}
/** Appel format prodfp < medium pour la vue/3 = meilleure performance */
function switchVisu() {

    var src_format = $('#list_item').length && $('#list_item').hasClass('view_per_three')? 'prodfp':'medium';
    var dest_format = $('#list_item').length && $('#list_item').hasClass('view_per_three')? 'medium':'prodfp';

    $('#list_item .productBigPictures img').each(function () {
        if(typeof($(this).attr('src'))!='undefined') {
            $(this).attr('src',$(this).attr('src').replace(src_format,dest_format));
        }
    });
}

/***** RAYON + SEARCH VIEWS CHANGE *****/

function viewChanger(){
    $( '.views .view' ).click(function() {
        if( ! $(this).hasClass('is-active') ) {
            $('.views .view').removeClass('is-active');
            $(this).addClass('is-active');
            switchVisu();
        }
    })

    $('#viewPerTwo').click(function() {
        if( $('#list_item').hasClass('view_per_three') ) {
            $('#list_item').removeClass('view_per_three');
        }
    })

    $('#viewPerThree').click(function() {
        if( ! $('#list_item').hasClass('view_per_three') ) {
            $('#list_item').addClass('view_per_three');
        }
    });
}

/**** ACTION CTA CODE PROMO ***/

$(function () {
    $('#input_codePromo').on("change keyup",function(){
        if($(this).val().length !=0) {
            $("#clientPromoErr").removeClass('cache');
            $(".form_submit #btn_cmd_valid").addClass('disable');           
        }else{
            $("#clientPromoErr").addClass('cache');
            $(".form_submit #btn_cmd_valid").removeClass('disable');
        }
    });

     $('.w-submit-button').on("click",function(){
        $("#clientPromoErr").addClass('cache');
        $(".form_submit #btn_cmd_valid").removeClass('disable');
    });

});
$(function () {
    $( '.js-form-selection-offer').removeClass('hover');
});


$.fn.initAssocBloc = function() {

    var element = $(this);

    function displayItem(element) {
        var list = [];

        $("a", element).each(function(index) {
            list.push($(this));
        });

        list = shuffle(list);

        var length = list.length;
        if (length > 4) {
            length = 4;
        }
        element.html('');
        for (var i = 0 ; i < length ; i++) {
            element.append(list[i]);
        }
    }

    /**
     * Shuffles array in place.
     * @param {Array} a items An array containing the items.
     */
    function shuffle(a) {
        var j, x, i;
        for (i = a.length - 1; i > 0; i--) {
            j = Math.floor(Math.random() * (i + 1));
            x = a[i];
            a[i] = a[j];
            a[j] = x;
        }
        return a;
    }

    displayItem(element);

};

/* Video vimeo page rayon sscat etc */
/*
function responsive_vimeo_video(){
    setTimeout(function(){
        $(".vimeo_video_ctn").parent().parent().parent().each(function(){
            var getWidth = $(this).next().find(".product_picture #nbr_visu_0:first-child img").width();
            var getHeight = $(this).next().find(".product_picture #nbr_visu_0:first-child img").height();
            
            $(".vimeo_video_ctn").css({'width':getWidth, 'height':getHeight});
            $(".vimeo_video_ctn iframe").css({'width':getWidth, 'height':getHeight});
        });
    }, 350);
}
responsive_vimeo_video();

function resize_vvideo(){
    $(window).resize(function() {
        responsive_vimeo_video();
    });
}
resize_vvideo();

$(function () {
    responsive_vimeo_video();
    resize_vvideo();
});
*/

$(document).ready(function video_responsive_r() {

    $(window).on('load resize', function () {

        var video_max_height = $('.category #scroll_items .item:not(.push) .imgWrapper').height();
        $('.category #scroll_items .push iframe').css('height', video_max_height);
    
    }).trigger('load resize');

});


function show_lightbox_3xcb(){
    openMultiShad('popup_cofidis3cb');
}

$(function () {
    /**
     * Page: contact_faq.php
     * Formulaire de contact
     * Append "En savoir plus" in "Je souhaite recevoir les meilleures offres par email et SMS"
     */
    appendElm($('#form_contact1').find('legend.w-fieldset-label'), $('#form_contact1').find('.optinLightboxContact'));
});

function appendElm($parentEl, $childEl) {
    if($parentEl.length && $childEl.length) {
        console.log($parentEl, $childEl);
        return $parentEl.append($childEl);
    }
}